import React, { useState, useRef, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-responsive-modal';
import Spinner from '../images/spinner.gif';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-modal/styles.css';
import { Helmet } from 'react-helmet';

import SzafaJedenFront from '../images/szafyIreagly/szafa-1.png';
import SJeden from '../images/szafyIreagly/szafa-1.jpg';
import SzJeden from '../images/szafyIreagly/szafa-1.1.jpg';
import SzaJeden from '../images/szafyIreagly/szafa-1.2.jpg';
import SzafJeden from '../images/szafyIreagly/szafa-1.3.jpg';
import SzafaJeden from '../images/szafyIreagly/szafa-1.4.jpg';

import SDwa from '../images/szafyIreagly/szafa-2.jpg';
import SzDwa from '../images/szafyIreagly/szafa-2.1.jpg';
import SzaDwa from '../images/szafyIreagly/szafa-2.2.jpg';
import SzafDwa from '../images/szafyIreagly/szafa-2.3.jpg';
import SzafaDwa from '../images/szafyIreagly/szafa-2.4.jpg';
import SzafaaDwa from '../images/szafyIreagly/szafa-2.5.jpg';

// import SzafaTrzyFront from '../images/szafyIreagly/szafa-3.png';
// import STrzy from '../images/szafyIreagly/szafa-3.jpg';
// import SzTrzy from '../images/szafyIreagly/szafa-3.1.jpg';
// import SzaTrzy from '../images/szafyIreagly/szafa-3.2.jpg';
// import SzafTrzy from '../images/szafyIreagly/szafa-3.3.jpg';

import SCztery from '../images/szafyIreagly/szafa-4.jpeg';
import SzCztery from '../images/szafyIreagly/szafa-4.1.jpeg';
import SzaCztery from '../images/szafyIreagly/szafa-4.2.jpeg';
import SzafCztery from '../images/szafyIreagly/szafa-4.3.jpeg';

import SPiec from '../images/szafyIreagly/szafa-5.jpeg';
import SzPiec from '../images/szafyIreagly/szafa-5.1.jpeg';
import SzaPiec from '../images/szafyIreagly/szafa-5.2.jpeg';

import SSzesc from '../images/szafyIreagly/szafa-6.jpeg';
import SzSzesc from '../images/szafyIreagly/szafa-6.1.jpeg';
import SzaSzesc from '../images/szafyIreagly/szafa-6.2.jpeg';

import SSiedem from '../images/szafyIreagly/szafa-7.jpg';
import SzSiedem from '../images/szafyIreagly/szafa-7.1.jpg';
import SzaSiedem from '../images/szafyIreagly/szafa-7.2.jpg';

import SOsiem from '../images/szafyIreagly/szafa-8.png';
import SzOsiem from '../images/szafyIreagly/szafa-8.1.jpg';
import SzaOsiem from '../images/szafyIreagly/szafa-8.2.jpg';
import SzafOsiem from '../images/szafyIreagly/szafa-8.3.jpg';
import SzafaOsiem from '../images/szafyIreagly/szafa-8.4.jpg';

import SDziewiec from '../images/szafyIreagly/szafa-9.jpg';
import SzDziewiec from '../images/szafyIreagly/szafa-9.1.jpg';
import SzaDziewiec from '../images/szafyIreagly/szafa-9.2.jpg';
import SzafDziewiec from '../images/szafyIreagly/szafa-9.3.jpg';

import SDziesiec from '../images/szafyIreagly/szafa-10.jpg';
import SzDziesiec from '../images/szafyIreagly/szafa-10.1.jpg';
import SzaDziesiec from '../images/szafyIreagly/szafa-10.2.jpg';

import SJedenascie from '../images/szafyIreagly/szafa-11.jpg';
import SzJedenascie from '../images/szafyIreagly/szafa-11.1.jpg';
import SzaJedenascie from '../images/szafyIreagly/szafa-11.2.jpg';
import SzafJedenascie from '../images/szafyIreagly/szafa-11.3.jpg';

import SDwanascie from '../images/szafyIreagly/szafa-12.jpg';
import SzDwanascie from '../images/szafyIreagly/szafa-12.1.jpg';
import SzaDwanascie from '../images/szafyIreagly/szafa-12.2.jpg';
import SzafDwanascie from '../images/szafyIreagly/szafa-12.3.jpg';

import STrzynascie from '../images/szafyIreagly/szafa-13.jpg';
import SzTrzynascie from '../images/szafyIreagly/szafa-13.1.jpg';
import SzaTrzynascie from '../images/szafyIreagly/szafa-13.2.jpg';
import SzafTrzynascie from '../images/szafyIreagly/szafa-13.3.jpg';

import SCzternascie from '../images/szafyIreagly/szafa-14.jpg';
import SzCzternascie from '../images/szafyIreagly/szafa-14.1.jpg';
import SzaCzternascie from '../images/szafyIreagly/szafa-14.2.jpg';
import SzafCzternascie from '../images/szafyIreagly/szafa-14.3.jpg';

const SzafyRegaly = () => {
  const [openModal, setOpenModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [cardLoading, setCardLoading] = useState(true);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (images) => {
    setGalleryImages(images);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cards = [
    {
      id: 13,
      image: STrzynascie,
      gallery: [
        { original: STrzynascie },
        { original: SzTrzynascie },
        { original: SzaTrzynascie },
        { original: SzafTrzynascie },
      ],
      description : 'Jasnografitowa sypialnia z oświelteniem LED'
    },
    {
      id: 14,
      image: SCzternascie,
      gallery: [
        { original: SCzternascie },
        { original: SzCzternascie },
        { original: SzaCzternascie },
        { original: SzafCzternascie },
      ],
      description : 'Kaszmirowa szafa z szufladami i złotymi uchwytami'
    },
    {
      id: 1,
      image: SDwa,
      gallery: [
        { original: SDwa },
        { original: SzDwa },
        { original: SzaDwa },
        { original: SzafDwa },
        { original: SzafaDwa },
        { original: SzafaaDwa },
      ],
      description : 'Ciemno szara szafa w przedpokoju z czarnymi uchwytami'
    },
    {
      id: 2,
      image: SPiec,
      gallery: [
        { original: SPiec },
        { original: SzPiec },
        { original: SzaPiec },
      ],
      description : 'Otwarta kratownica na książki'
    },
    {
      id: 3,
      image: SCztery,
      gallery: [
        { original: SCztery },
        { original: SzCztery },
        { original: SzaCztery },
        { original: SzafCztery },
      ],
      description : 'Otwarta garderoba w szarym kolorze z akcentem róźowego'
    },
    {
      id: 6,
      image: SJedenascie,
      gallery: [
        { original: SJedenascie },
        { original: SzJedenascie },
        { original: SzaJedenascie },
        { original: SzafJedenascie }
      ],
      description : 'Jasnografitowa meblościanka bezuchwytowa z oświetleniem LED'
    },
    {
      id: 7,
      image: SSiedem,
      gallery: [
        { original: SSiedem },
        { original: SzSiedem },
        { original: SzaSiedem },
      ],
      description : 'Szafa przesuwna z szampańskim okuciem w kolorze brzozy'
    },
    {
      id: 12,
      image: SDwanascie,
      gallery: [
        { original: SDwanascie },
        { original: SzDwanascie },
        { original: SzaDwanascie },
        { original: SzafDwanascie }
      ],
      description : 'Bezokuciowa szafa samohamująca z oświetleniem LED'
    },
    {
      id: 4,
      image: SSzesc,
      gallery: [
        { original: SSzesc },
        { original: SzSzesc },
        { original: SzaSzesc },
      ],
      description : 'Szafa na poddaszu ze ściętym skosem i szufladami'
    },
    {
      id: 5,
      image: SOsiem,
      gallery: [
        { original: SzOsiem },
        { original: SzaOsiem },
        { original: SzafOsiem },
        { original: SzafaOsiem },
      ],
      description : 'Dwukolorowe szafy do pokojów dziecięcych'
    },
    {
      id: 8,
      image: SDziesiec,
      gallery: [
        { original: SDziesiec },
        { original: SzDziesiec },
        { original: SzaDziesiec },
      ],
      description : 'Przesuwna szafa w przedpokoju z lustrzanymi drzwami'
    },
    {
      id: 9,
      image: SzafaJedenFront,
      gallery: [
        { original: SJeden },
        { original: SzJeden },
        { original: SzaJeden },
        { original: SzafJeden },
        { original: SzafaJeden },
      ],
      description : 'Ciemno szara szafa z siedziskiem z czarnymi uchwytami'
    },
    {
      id: 10,
      image: SDziewiec,
      gallery: [
        { original: SDziewiec },
        { original: SzDziewiec },
        { original: SzaDziewiec },
        { original: SzafDziewiec },
      ],
      description : 'Szafa przesuwna w wiatrołapie z aluminiowymi okuciami'
    },
    // {
    //   id: 11,
    //   image: SzafaTrzyFront,
    //   gallery: [
    //     { original: STrzy },
    //     { original: SzTrzy },
    //     { original: SzaTrzy },
    //     { original: SzafTrzy },
    //   ],
    //   description : 'Wisząca szafa w salonie z podświetlanymi półkami LED'
    // },
  ];

  const galleryItems = galleryImages.map((image) => ({
    original: image.original,
    thumbnail: image.original,
  }));

  return (
    <section className='meble' ref={scrollContainerRef}>
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/szafy-i-regaly" />
        <title>Szafy i regały na wymiar Tarnów</title>
        <meta name="description" content="Zamów indywidualnie zaprojektowane szafy i regały na wymiar w Tarnowie, które dostosujemy do Twoich potrzeb. Skontaktuj się z nami już dziś, aby rozpocząć tworzenie szaf i regałów, które idealnie wpasują się w Twoje wnętrze i zapewnią praktyczność oraz styl!" />
      </Helmet>
      <div className="card-grid">
        {cards.map((card) => (
          <div 
            className='card' 
            key={card.id} 
            onClick={() => handleCardClick(card.gallery)}
          >
              {cardLoading && (
                <img src={Spinner} className='mini-spinner' alt="Loading Spinner" />
              )}
              <img
                src={card.image}
                alt={card.description}
                onLoad={() => setCardLoading(false)}
                style={{ display: cardLoading ? 'none' : 'block' }}
              />
            <div className="card-overlay">
              <span className="card-text">Zobacz galerię</span>
            </div>
            <div className="card-description">{card.description}</div>
          </div>
        ))}
      </div>
      <Modal open={openModal} onClose={closeModal} center>
        <div className="image-gallery-container">
        {galleryImages.length > 0 ? (
              <ImageGallery items={galleryItems} additionalClass="equal-width" />
            ) : (
              <img src={Spinner} alt="Loading Spinner" />
            )}
        </div>
      </Modal>
      <div className='subpage-description'>
        <h1 className='realizacje-header'><strong>Tarnów meble na wymiar</strong> - Twórz swoje idealne przestrzenie z naszymi szafami i regałami.</h1>
        <p>Szukasz funkcjonalnych i estetycznych rozwiązań do przechowywania w swoim domu lub biurze? Oferujemy meble na wymiar w Tarnowie, w tym szafy i regały, które doskonale spełnią Twoje oczekiwania pod względem zarówno użytkowym, jak i wizualnym.</p>
        <p>Nasze szafy i regały na wymiar to idealne rozwiązanie, aby optymalnie wykorzystać dostępną przestrzeń. Bez względu na to, czy potrzebujesz szafy na ubrania, szafę wnękową, regał na książki czy szafę na dokumenty, nasz zespół projektantów i stolarzy zaprojektuje i wykona meble, które będą idealnie pasować do Twoich potrzeb.</p>
        <p>Nasze <strong>Tarnów meble na wymiar</strong> są wykonane z najwyższą starannością i z użyciem wysokiej jakości materiałów. Dzięki temu są nie tylko trwałe, ale również estetyczne. Nasz zespół stolarzy dba o najdrobniejsze detale, aby zapewnić Ci meble, które są nie tylko praktyczne, ale także piękne.</p>
        <p>Stwórz swoje idealne przestrzenie z naszymi szafami i regałami na wymiar w Tarnowie. Skontaktuj się z nami już dziś, aby umówić się na spotkanie z naszym projektantem i rozpocząć realizację Twojego projektu. Jesteśmy gotowi pomóc Ci w zaprojektowaniu i wykonaniu mebli, które będą idealnie dopasowane do Twoich potrzeb i stylu życia.</p>
      </div>
    </section>
  );
};

export default SzafyRegaly;