import HomeImage from '../components/HomeImage';
import About from '../components/About';
import Oferta from '../components/Oferta';
import Realizacje from '../components/Realizacje';
import Contact from '../components/Contact';
import { Helmet } from 'react-helmet';

const Home = () => {
  return(
    <>
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/" />
      </Helmet>
      <HomeImage />
      <About />
      <Oferta />
      <Realizacje />
      <Contact />
    </>
  )
}

export default Home;