import React, { useState, useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-responsive-modal';
import Spinner from '../images/spinner.gif';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-modal/styles.css';
import { Helmet } from 'react-helmet';

import BJeden from '../images/biuro/biuro-1.jpeg';
import BiJeden from '../images/biuro/biuro-1.1.jpeg';
import BiuJeden from '../images/biuro/biuro-1.2.jpeg';
import BiurJeden from '../images/biuro/biuro-1.3.jpeg';

import BDwa from '../images/biuro/biuro-2.jpeg';
import BiDwa from '../images/biuro/biuro-2.1.jpeg';
import BiuDwa from '../images/biuro/biuro-2.2.jpeg';

import BTrzy from '../images/biuro/biuro-3.png';
import BiTrzy from '../images/biuro/biuro-3.1.jpg';
import BiuTrzy from '../images/biuro/biuro-3.2.jpg';
import BiurTrzy from '../images/biuro/biuro-3.3.jpg';
import BiuroTrzy from '../images/biuro/biuro-3.4.jpg';

import BCztery from '../images/biuro/biuro-4.jpg';
import BiCztery from '../images/biuro/biuro-4.1.jpg';
import BiuCztery from '../images/biuro/biuro-4.2.jpg';
import BiurCztery from '../images/biuro/biuro-4.3.jpg';

import BPiec from '../images/biuro/biuro-5.jpg';
import BiPiec from '../images/biuro/biuro-5.1.jpg';
import BiuPiec from '../images/biuro/biuro-5.2.jpg';
import BiurPiec from '../images/biuro/biuro-5.3.jpg';

const MebleBiurowe = () => {
  const [openModal, setOpenModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [cardLoading, setCardLoading] = useState(true);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (images) => {
    setGalleryImages(images);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cards = [
    {
      id: 2,
      image: BDwa,
      gallery: [
        { original: BDwa },
        { original: BiDwa },
        { original: BiuDwa },
      ],
      description: 'Biurko z szafką na ksiązki oraz zakmniętą szafą na ubrania',
    },
    {
      id: 6,
      image: BPiec,
      gallery: [
        { original: BPiec },
        { original: BiPiec },
        { original: BiuPiec },
        { original: BiurPiec },
      ],
      description: 'Biuro dwustanowiskowe przedzielone obrotowymi orzechowymi lamelami',
    },
    {
      id: 1,
      image: BJeden,
      gallery: [
        { original: BiJeden },
        { original: BiuJeden },
        { original: BJeden },
        { original: BiurJeden },
      ],
      description: 'Biurko do salonu kosmetycznego z oświetlanym na dotyk lustrem',
    },
    {
      id: 3,
      image: BTrzy,
      gallery: [
        { original: BiTrzy },
        { original: BiuTrzy },
        { original: BiurTrzy },
        { original: BiuroTrzy },
      ],
      description: 'Białe biurko z szufladami',
    },
    {
      id: 4,
      image: BCztery,
      gallery: [
        { original: BCztery },
        { original: BiCztery },
      ],
      description: 'Żółto-czarne biurko do pokoju dziecięcego',
    },
    {
      id: 5,
      image: BiuCztery,
      gallery: [
        { original: BiuCztery },
        { original: BiurCztery },
      ],
      description: 'Zielono-czarne biurko do pokoju dziecięcego',
    },
  ];

  const galleryItems = galleryImages.map((image) => ({
    original: image.original,
    thumbnail: image.original,
  }));

  return (
    <section className='meble' ref={scrollContainerRef}>
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/meble-biurowe" />
        <title>Meble biurowe na wymiar Tarnów</title>
        <meta name="description" content="Zamów meble kuchenne na wymiar Tarnów i okolice. Stwórz swoją wymarzoną kuchnię z funkcjonalnymi i stylowymi meblami. Skontaktuj się z nami, aby rozpocząć tworzenie Twojej idealnej kuchni!" />
      </Helmet>
      <div className="card-grid">
        {cards.map((card) => (
          <div
            className="card"
            key={card.id}
            onClick={() => handleCardClick(card.gallery)}
          >
              {cardLoading && (
                <img src={Spinner} className='mini-spinner' alt="Loading Spinner" />
              )}
              <img
                src={card.image}
                alt={card.description}
                onLoad={() => setCardLoading(false)}
                style={{ display: cardLoading ? 'none' : 'block' }}
              />
            <div className="card-overlay">
              <span className="card-text">Zobacz galerię</span>
            </div>
            <div className="card-description">{card.description}</div>
          </div>
        ))}
      </div>
      <Modal open={openModal} onClose={closeModal} center>
        <div className="modal-content">
          <div className="image-gallery-container">
          {galleryImages.length > 0 ? (
              <ImageGallery items={galleryItems} additionalClass="equal-width" />
            ) : (
              <img src={Spinner} alt="Loading Spinner" />
            )}
          </div>
        </div>
      </Modal>
      <div className='subpage-description'>
        <h1 className='realizacje-header'><strong>Meble Tarnów</strong>- Doskonałe rozwiązania dla Twojego biura</h1>
        <p>Poszukujesz wysokiej jakości mebli biurowych, które zapewnią funkcjonalność i komfort w Twoim miejscu pracy? Zapraszamy do zapoznania się z naszą ofertą mebli w Tarnowie. Oferujemy szeroki wybór mebli biurowych, które spełnią Twoje oczekiwania pod względem jakości, designu i ergonomii.</p>
        <p>Wszystkie nasze <strong>meble Tarnów</strong> są starannie wykonane z wysokiej jakości materiałów, co gwarantuje trwałość i niezawodność. Dzięki temu będziesz mógł cieszyć się funkcjonalnością i estetyką naszych mebli przez wiele lat.</p>
        <p>Nasz zespół projektantów i stolarzy pomoże Ci zaprojektować i dostosować meble do Twoich indywidualnych wymagań. Niezależnie od tego, czy potrzebujesz biurka o nietypowym kształcie czy szafki na dokumenty o określonym rozmiarze, jesteśmy gotowi spełnić Twoje oczekiwania.</p>
        <p>Inwestując w nasze meble biurowe, inwestujesz w wydajność i komfort pracy. Skontaktuj się z nami już dziś, aby umówić się na spotkanie i omówić swoje potrzeby dotyczące mebli biurowych. Jesteśmy gotowi dostarczyć Ci meble, które optymalnie wykorzystają przestrzeń i pozwolą Ci stworzyć funkcjonalne i estetyczne środowisko pracy.</p>
      </div>
    </section>
  );
};

export default MebleBiurowe;