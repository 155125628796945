import { Link as ScrollLink } from 'react-scroll';

const HomeImage = () => {
  return(
    <section>
      <div className="fullscreen-div">
        <div className="background-image">
          <div id='top'></div>
        </div>
        <h1 className="centered-text centered-header">UMEBLUJEMY TWÓJ DOM</h1>
        <h2 className="centered-text centered-para">Meble kuchenne, pokojowe i łazienkowe na wymiar</h2>
        <ScrollLink
          to="WilkWoodAbout"
          smooth={true}
          duration={800}
          offset={-90}
          className="arrow-down"
        >
          <span></span>
          <span></span>
          <span></span>
        </ScrollLink>
      </div>
    </section>
  )
}

export default HomeImage;