import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import blogFirstHome from '../images/blog/blog-1-home.jpg';
import blogSecondHome from '../images/blog/blog-2-home.jpg';
import blogThirdHome from '../images/blog/blog-3-home.jpg';
import blogFourthHome from '../images/blog/blog-4-home.jpg';
import blogFifthHome from '../images/blog/blog-5-home.jpg';

const Blog = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    { 
      id: 1, 
      foto: blogFirstHome, 
      link: '/blog/meble-na-wymiar-do-malego-mieszkania', 
      text: 'Meble do małego mieszkania' },
    { 
      id: 2, 
      foto: blogSecondHome, 
      link: '/blog/meble-a-przestrzen-w-twoim-domu', 
      text: 'Przestrzeń w Twoim domu' },
    { 
      id: 3, 
      foto: blogThirdHome, 
      link: '/blog/innowacyjne-rozwiazania', 
      text: 'Innowacyjne rozwiązania' },
    { 
      id: 4, 
      foto: blogFourthHome, 
      link: '/blog/wyposazenie-domowej-pracowni', 
      text: 'Wyposażenie domowej pracowni' },
    { 
      id: 5, 
      foto: blogFifthHome, 
      link: '/blog/meble-na-wymiar-w-malopolsce', 
      text: 'Meble na wymiar' },
  ];

  return (
    <section className='meble'>
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/blog" />
        <title>Meble na wymiar Tarnów - WilkWood Blog</title>
        <meta name="description" content="Odkryj najnowsze trendy i inspiracje w świecie meble na wymiar Tarnów dzięki blogowi Wilkwood." />
      </Helmet>
      <div className='section-header blog'>
        <h1>Blog</h1>
      </div>
      <div className="blog-grid">
      {images.map((image) => (
        <Link key={image.id} to={image.link}>
          <div className="blogimage-container">
            <img src={image.foto} alt={image.id} />
            <div className="image-text">{image.text}</div>
          </div>
        </Link>
      ))}
    </div>
    </section>
  );
};

export default Blog;