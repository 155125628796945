import { Element } from 'react-scroll';

const About = () => {
  return (
    <section>
      <Element id="WilkWoodAbout" name="WilkWood O Nas" className="section-below">
        <div className="section-header">
          <h2>WilkWood - Meble Na Wymiar</h2>
        </div>
        <div className="right-column">
          <p>Jesteśmy doświadczoną firmą, specjalizującą się w projektowaniu i produkcji wysokiej jakości mebli na wymiar. Nasze meble są starannie wykonane z dbałością o każdy detal, zapewniając trwałość i solidność na lata.</p>
          <p>Wilkwood - <strong>meble na wymiar Tarnów</strong> i okolice. Działamy na lokalnym rynku, dostarczając naszym klientom w Tarnowie i okolicach spersonalizowane rozwiązania meblowe.</p>
          <p>Naszym celem jest zapewnienie satysfakcji naszym klientom poprzez dostarczanie funkcjonalnych i estetycznych rozwiązań. Nasz zespół pasjonatów pracuje z zaangażowaniem, aby dostarczyć unikalne meble, dopasowane do Twoich potrzeb.</p>
          <p>Przykładamy ogromną wagę do estetyki, trwałości i funkcjonalności naszych mebli. Starannie dobieramy wysokiej jakości materiały od renomowanych dostawców, aby stworzyć meble, które będą służyć Ci przez wiele lat.</p>
          <p>Nasza oferta obejmuje szeroki wybór mebli na wymiar, takich jak kuchnie, łazienki, garderoby, szafy wnękowe, biurka i wiele innych.</p>
          <p>Zapraszamy do zapoznania się z naszym portfolio i skontaktowania się z nami, aby stworzyć wymarzone wnętrze!</p>
        </div>
      </Element>
    </section>
  )
}

export default About;