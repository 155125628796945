import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MebleKuchenne from "./pages/MebleKuchenne";
import MebleLazienkowe from "./pages/MebleLazienkowe";
import MebleBiurowe from "./pages/MebleBiurowe";
import SzafyRegaly from "./pages/SzafyRegaly";
import Footer from './components/Footer';
import Blog from "./pages/Blog";
import FirstBlog from "./pages/blogPages/FirstBlog";
import SecondBlog from "./pages/blogPages/SecondBlog";
import ThirdBlog from "./pages/blogPages/ThirdBlog";
import FourthBlog from "./pages/blogPages/FourthBlog";
import Obszar from "./pages/Obszar";
import FifthBlog from "./pages/blogPages/FifthBlog";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/meble-kuchenne" element={<MebleKuchenne />} />
        <Route path="/szafy-i-regaly" element={<SzafyRegaly />} />
        <Route path="/meble-biurowe" element={<MebleBiurowe />} />
        <Route path="/meble-lazienkowe" element={<MebleLazienkowe />} />
        <Route path="/obszar" element={<Obszar />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/meble-na-wymiar-do-malego-mieszkania" element={<FirstBlog />} />
        <Route path="/blog/meble-a-przestrzen-w-twoim-domu" element={<SecondBlog />} />
        <Route path="/blog/innowacyjne-rozwiazania" element={<ThirdBlog />} />
        <Route path="/blog/wyposazenie-domowej-pracowni" element={<FourthBlog />} />
        <Route path="/blog/meble-na-wymiar-w-malopolsce" element={<FifthBlog />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
