import { Element } from "react-scroll";
import { Link } from "react-router-dom";

const realizacje = [
  { to: "/meble-kuchenne", className: "kuchnia", title: "MEBLE KUCHENNE" },
  { to: "/szafy-i-regaly", className: "szafy", title: "SZAFY I REGAŁY" },
  { to: "/meble-biurowe", className: "biuro", title: "MEBLE BIUROWE" },
  { to: "/meble-lazienkowe", className: "lazienka", title: "MEBLE ŁAZIENKOWE" }
];

const Realizacje = () => {
  return (
    <section>
      <Element name="WilkWoodRealizacje" className="section-below">
        <div className="section-header">
          <h3 id="WilkWoodRealizacje">Nasze Realizacje</h3>
        </div>
        <div className="grid-container">
          {realizacje.map(category => (
            <Link
              key={category.to}
              to={category.to}
              className={`grid-item ${category.className}`}
              style={{ textDecoration: 'none' }}
            >
              <div className="text">
                <h4>{category.title}</h4>
              </div>
            </Link>
          ))}
        </div>
      </Element>
    </section>
  );
};

export default Realizacje;
