import { Element } from 'react-scroll';

import prod from '../images/contact-icons/prod.png';
import consult from '../images/contact-icons/konsultacja.png';
import montaz from '../images/contact-icons/montaz.png'

const Oferta = () => {
  return (
    <section>
      <Element id="WilkWoodOferta" name="WilkWood O Nas" className="section-below">
        <div className='section-header'>
          <h3>Proces Realizacji</h3>
        </div>
        <div className="kafelki-container">
      <div className="kafelek">
        <div className="kafelek-icon">
          <img src={consult} className="kafelek-img" alt="Pomiar i wycena mebli na wymiar" loading='lazy'/>
        </div>
        <h4 className="kafelek-naglowek">Pomiar i wycena</h4>
        <p className="kafelek-opis">Umów się na spotkanie z naszym doświadczonym stolarzem, który pomoże Ci zrealizować Twoje wizje dotyczące mebli na wymiar. Podczas konsultacji omówimy Twoje potrzeby, zaprezentujemy różne rozwiązania projektowe i przygotujemy dla Ciebie szczegółową wycenę, dopasowaną do Twoich preferencji.</p>
      </div>

      <div className="kafelek">
        <div className="kafelek-icon">
          <img src={prod} className="kafelek-img" alt="Produkcja i wycena mebli na wymiar" loading='lazy'/>
        </div>
        <h4 className="kafelek-naglowek">Produkcja i dostawa</h4>
        <p className="kafelek-opis">Po zaakceptowaniu projektu rozpoczynamy produkcję mebli na wymiar w naszym profesjonalnym warsztacie meblarskim. Dzięki zastosowaniu wysokiej jakości materiałów i precyzyjnym technikom stolarskim, tworzymy trwałe, funkcjonalne i estetyczne meble, dopasowane do Twoich indywidualnych potrzeb. Następnie dostarczymy zamówione meble bezpośrednio do Twojego domu.</p>
      </div>

      <div className="kafelek">
        <div className="kafelek-icon">
          <img src={montaz} className="kafelek-img" alt="Montaż mebli na wymiar" loading='lazy'/>
        </div>
        <h4 className="kafelek-naglowek">Montaż i zadowolenie</h4>
        <p className="kafelek-opis">Nasz profesjonalny zespół rzemieślników zajmie się montażem zamówionych mebli w Twoim domu. Dbałość o detale i precyzja to nasze priorytety, aby zapewnić idealne dopasowanie i trwałość mebli. Naszym celem jest pełne zadowolenie klientów, dlatego dokładamy wszelkich starań, abyście byli z naszych usług w pełni zadowoleni.</p>
      </div>
    </div>
      </Element>
    </section>
  )
}

export default Oferta;