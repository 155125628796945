import React, { useState, useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-responsive-modal';
import Spinner from '../images/spinner.gif';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-modal/styles.css';
import { Helmet } from 'react-helmet';

import KJeden from '../images/kuchnia/kuchnia-1.jpg';
import KuJeden from '../images/kuchnia/kuchnia-1.1.jpg';
import KucJeden from '../images/kuchnia/kuchnia-1.2.jpg';
import KuchJeden from '../images/kuchnia/kuchnia-1.3.jpg';
import KuchnJeden from '../images/kuchnia/kuchnia-1.4.jpg';

import KDwa from '../images/kuchnia/kuchnia-2.jpg';
import KuDwa from '../images/kuchnia/kuchnia-2.1.jpg';
import KucDwa from '../images/kuchnia/kuchnia-2.2.jpg';
import KuchDwa from '../images/kuchnia/kuchnia-2.3.jpg';

import KTrzy from '../images/kuchnia/kuchnia-3.jpg';
import KuTrzy from '../images/kuchnia/kuchnia-3.1.jpg';
import KucTrzy from '../images/kuchnia/kuchnia-3.2.jpg';
import KuchTrzy from '../images/kuchnia/kuchnia-3.3.jpg';
import KuchnTrzy from '../images/kuchnia/kuchnia-3.4.jpg';
import KuchniTrzy from '../images/kuchnia/kuchnia-3.5.jpg';
import KTrzyFront from '../images/kuchnia/kuchnia-3front.png';

import KCzteryFront from '../images/kuchnia/kuchnia-4front.jpg';
import KCztery from '../images/kuchnia/kuchnia-4.jpg';
import KuCztery from '../images/kuchnia/kuchnia-4.1.jpg';
import KucCztery from '../images/kuchnia/kuchnia-4.2.jpg';
import KuchCztery from '../images/kuchnia/kuchnia-4.3.jpg';
import KuchnCztery from '../images/kuchnia/kuchnia-4.4.jpg';

import KPiec from '../images/kuchnia/kuchnia-5.jpg';
import KuPiec from '../images/kuchnia/kuchnia-5.1.jpg';
import KucPiec from '../images/kuchnia/kuchnia-5.2.jpg';
import KuchPiec from '../images/kuchnia/kuchnia-5.3.jpg';

import KSzescFront from '../images/kuchnia/kuchnia-6front.png';
import Kszesc from '../images/kuchnia/kuchnia-6.jpg';
import Kuszesc from '../images/kuchnia/kuchnia-6.1.jpg';
import Kucszesc from '../images/kuchnia/kuchnia-6.2.jpg';
import Kuchszesc from '../images/kuchnia/kuchnia-6.3.jpg';
import Kuchnszesc from '../images/kuchnia/kuchnia-6.4.jpg';

import KSiedemFront from '../images/kuchnia/kuchnia-7front.jpg';
import KSiedem from '../images/kuchnia/kuchnia-7.jpg';
import KuSiedem from '../images/kuchnia/kuchnia-7.1.jpg';
import KucSiedem from '../images/kuchnia/kuchnia-7.2.jpg';
import KuchSiedem from '../images/kuchnia/kuchnia-7.3.jpg';

import KOsiem from '../images/kuchnia/kuchnia-8.jpg';
import KuOsiem from '../images/kuchnia/kuchnia-8.1.jpg';
import KucOsiem from '../images/kuchnia/kuchnia-8.2.jpg';
import KuchOsiem from '../images/kuchnia/kuchnia-8.3.jpg';

import KDziewiec from '../images/kuchnia/kuchnia-9.jpg';
import KuDziewiec from '../images/kuchnia/kuchnia-9.1.jpg';
import KucDziewiec from '../images/kuchnia/kuchnia-9.2.jpg';
import KuchDziewiec from '../images/kuchnia/kuchnia-9.3.jpg';
import KuchnDziewiec from '../images/kuchnia/kuchnia-9.4.jpg';
import KuchniDziewiec from '../images/kuchnia/kuchnia-9.5.jpg';
import KuchniaDziewiec from '../images/kuchnia/kuchnia-9.6.jpg';

import KDziesiec from '../images/kuchnia/kuchnia-10.jpg';
import KuDziesiec from '../images/kuchnia/kuchnia-10.1.jpg';
import KucDziesiec from '../images/kuchnia/kuchnia-10.2.jpg';
import KuchDziesiec from '../images/kuchnia/kuchnia-10.3.jpg';
import KuchnDziesiec from '../images/kuchnia/kuchnia-10.4.jpg';

import KJedenascie from '../images/kuchnia/kuchnia-11.jpg';
import KuJedenascie from '../images/kuchnia/kuchnia-11.1.jpg';
import KucJedenascie from '../images/kuchnia/kuchnia-11.2.jpg';
import KuchJedenascie from '../images/kuchnia/kuchnia-11.3.jpg';
import KuchnJedenascie from '../images/kuchnia/kuchnia-11.4.jpg';


const MebleKuchenne = () => {
  const [openModal, setOpenModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [cardLoading, setCardLoading] = useState(true);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (images) => {
    setGalleryImages(images);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cards = [
    {
      id: 7,
      image: KSiedemFront,
      gallery: [
        { original: KSiedem },
        { original: KuSiedem },
        { original: KucSiedem },
        { original: KuchSiedem },
      ],
      description : 'Biała kuchnia w połysku z drewnianymi uchwytami',
    },
    {
      id: 11,
      image: KJedenascie,
      gallery: [
        { original: KJedenascie },
        { original: KuJedenascie },
        { original: KucJedenascie },
        { original: KuchJedenascie },
        { original: KuchnJedenascie },
      ],
      description : 'Migdałowe i granatowe fronty z blatem przechodzącym w parapet',
    },
    {
      id: 10,
      image: KDziesiec,
      gallery: [
        { original: KDziesiec },
        { original: KuDziesiec },
        { original: KucDziesiec },
        { original: KuchDziesiec },
        { original: KuchnDziesiec },
      ],
      description : 'Kaszmirowa kuchnia z drewnianymi frontami',
    },
    {
      id: 9,
      image: KDziewiec,
      gallery: [
        { original: KDziewiec },
        { original: KuDziewiec },
        { original: KucDziewiec },
        { original: KuchDziewiec },
        { original: KuchnDziewiec },
        { original: KuchniDziewiec },
        { original: KuchniaDziewiec },
      ],
      description : 'Antracytowa kuchnia przełamana blatem dąb wotan',
    },
    {
      id: 3,
      image: KTrzyFront,
      gallery: [
        { original: KuchTrzy },
        { original: KuchnTrzy },
        { original: KuchniTrzy },
        { original: KTrzy },
        { original: KuTrzy },
        { original: KucTrzy },
      ],
      description : 'Biało-szara kuchnia z ciemno grafitowym blatem',
    },
    {
      id: 8,
      image: KOsiem,
      gallery: [
        { original: KOsiem },
        { original: KuOsiem },
        { original: KucOsiem },
        { original: KuchOsiem },
      ],
      description : 'Grafitowo-biała kuchnia w połysku z dębowymi uchwytami',
    },
    {
      id: 2,
      image: KDwa,
      gallery: [
        { original: KuDwa },
        { original: KucDwa },
        { original: KuchDwa },
      ],
      description : 'Kuchnia w jasnym kolorze drewna z czarnymi akcentami',
    },
    {
      id: 4,
      image: KCzteryFront,
      gallery: [
        { original: KCztery },
        { original: KuCztery },
        { original: KucCztery },
        { original: KuchCztery },
        { original: KuchnCztery },
      ],
      description : 'Kuchnia w odcieniach szarości z drewnianym blatem',
    },
    {
      id: 1,
      image: KJeden,
      gallery: [
        { original: KJeden },
        { original: KuJeden },
        { original: KucJeden },
        { original: KuchJeden },
        { original: KuchnJeden },
      ],
      description : 'Jasnoszara kuchnia z metalowymi uchwytami i ciemnym blatem',
    },
    {
      id: 5,
      image: KPiec,
      gallery: [
        { original: KPiec },
        { original: KuPiec },
        { original: KucPiec },
        { original: KuchPiec },
      ],
      description : 'Jasna kuchnia z blatem imitującym beton',
    },
    {
      id: 6,
      image: KSzescFront,
      gallery: [
        { original: Kszesc },
        { original: Kuszesc },
        { original: Kucszesc },
        { original: Kuchszesc },
        { original: Kuchnszesc },
      ],
      description : 'Kuchnia w kolorze jasnego drewna z blatem imitującym marmur',
    },
  ];

  const galleryItems = galleryImages.map((image) => ({
    original: image.original,
    thumbnail: image.original,
  }));

  return (
    <section className='meble' ref={scrollContainerRef} >
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/meble-kuchenne" />
        <title>Meble kuchenne na wymiar Tarnów</title>
        <meta name="description" content="Zamów meble biurowe na wymiar Tarnów i okolice. Oferujemy unikalne rozwiązania meblarskie dostosowane do Twoich potrzeb. Skontaktuj się z nami już dziś!" />
      </Helmet>
      <div className="card-grid">
        {cards.map((card) => (
          <div 
            className={`card card${card.id}`} 
            key={card.id} 
            onClick={() => handleCardClick(card.gallery)}
          >
              {cardLoading && (
                <img src={Spinner} className='mini-spinner' alt="Loading Spinner" />
              )}
              <img
                src={card.image}
                alt={card.description}
                onLoad={() => setCardLoading(false)}
                style={{ display: cardLoading ? 'none' : 'block' }}
              />
            <div className="card-overlay">
              <span className="card-text">Zobacz galerię</span>
            </div>
            <div className="card-description">{card.description}</div>
          </div>
        ))}
      </div>
      <Modal open={openModal} onClose={closeModal} center>
        <div className="image-gallery-container">
        {galleryImages.length > 0 ? (
              <ImageGallery items={galleryItems} additionalClass="equal-width" />
            ) : (
              <img src={Spinner} alt="Loading Spinner" />
            )}
        </div>
      </Modal>
      <div className='subpage-description'>
        <h1 className='realizacje-header'><strong>Kuchnia na wymiar Tarnów</strong> - Stwórz idealne meble kuchenne dla swojego domu</h1>
        <p>Szukasz funkcjonalnych i estetycznych mebli kuchennych, które idealnie dopasują się do Twojego wnętrza? Zapraszamy do skorzystania z naszych usług projektowania i wykonawstwa mebli kuchennych na wymiar w Tarnowie. Oferujemy rozwiązania, które pozwolą Ci stworzyć kuchnię marzeń, dostosowaną do Twoich indywidualnych potrzeb i preferencji.</p>
        <p>Meble kuchenne na wymiar to gwarancja funkcjonalności i optymalnego wykorzystania przestrzeni. Dzięki naszej bogatej ofercie materiałów, kolorów i stylów możemy stworzyć kuchnię, która odzwierciedla Twój wyjątkowy gust i charakter domu. Bez względu na to, czy preferujesz nowoczesne wzornictwo, czy klasyczną elegancję, nasz zespół projektantów pomoże Ci stworzyć unikalne rozwiązanie, które spełni wszystkie Twoje oczekiwania - <strong>kuchnia na wymiar Tarnów</strong>.</p>
        <p>Przy współpracy z nami możesz mieć pewność, że meble kuchenne będą wykonane z najwyższą starannością i z użyciem wysokiej jakości materiałów. Nasi doświadczeni stolarze i specjaliści od montażu zapewnią, że każdy detal będzie dopracowany z dbałością o najmniejsze szczegóły. Dzięki temu Twoja nowa kuchnia będzie nie tylko estetyczna, ale także trwała i funkcjonalna.</p>
        <p>Zainwestuj w swoje wnętrze i stwórz kuchnię, w której przyjemnie spędzać czas i gotować. Skontaktuj się z nami już dziś, aby umówić się na darmową wizytę projektanta i rozpocząć realizację swojego projektu mebli kuchennych na wymiar w Tarnowie. Nasz zespół chętnie pomoże Ci spełnić wszystkie Twoje marzenia dotyczące idealnej kuchni.</p>
      </div>
    </section>
  );
};

export default MebleKuchenne;