import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, animateScroll } from 'react-scroll';
import logomeble from '../images/meble-logo-napis.png';
import meblelogo from '../images/meble-logo.png';

const handleScrollToRealizacje = () => {
  const section = document.getElementById('WilkWoodRealizacje');
  if (section) {
    const scrollTo = section.offsetTop;
    animateScroll.scrollTo(scrollTo, {
      duration: 1000,
      smooth: 'easeInOutQuart',
    });
  }
};

const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSubPage, setSubPage] = useState(false);
  const [isScrolling, setScrolling] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = () => {
    setMenuOpen(false);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleHomeClick = () => {
    if (isSubPage) {
      window.location.href = '/';
    } else {
      animateScroll.scrollToTop();
      setMenuOpen(false);
    }
  };

  const handleBackClick = () => {
    navigate('/', { replace: true });
    setTimeout(() => {
      handleScrollToRealizacje();
    }, 100);
  };

  const handleScroll = () => {
    setScrolling(true);
  };

  useEffect(() => {
    const isSubPage = location.pathname !== '/';
    setSubPage(isSubPage);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#WilkWoodRealizacje') {
      handleScrollToRealizacje();
      if (typeof window.history.replaceState === 'function') {
        window.history.replaceState({}, document.title, window.location.pathname);
      } else {
        window.location.hash = '';
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolling) {
      setMenuOpen(false);
      setScrolling(false);
    }
  }, [isScrolling]);

  const logoSource = windowWidth >= 790 ? logomeble : meblelogo;

  return (
    <nav className="navBar">
      <ScrollLink to="top" className="mr-auto" onClick={handleHomeClick}>
        <img src={logoSource} alt="WilkWood - Meble Na Wymiar" className="navLogo" />
      </ScrollLink>

      {windowWidth < 550 && !isSubPage ? (
        <div className={`menuIcon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
          <div className="menuIcon__bar" />
          <div className="menuIcon__bar" />
          <div className="menuIcon__bar" />
        </div>
      ) : (
        <div>
          {isSubPage ? (
            <button className="navLink right" onClick={handleBackClick}>
              &lt; POWRÓT
            </button>
          ) : (
            <ScrollLink to="top" className="navLink" onClick={handleHomeClick}>
              HOME
            </ScrollLink>
          )}
          <ScrollLink
            to="WilkWoodAbout"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            {isSubPage ? '' : 'O NAS'}
          </ScrollLink>
          <ScrollLink
            to="WilkWoodOferta"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            {isSubPage ? '' : 'OFERTA'}
          </ScrollLink>
          <ScrollLink
            to="WilkWoodRealizacje"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            {isSubPage ? '' : 'REALIZACJE'}
          </ScrollLink>
          <ScrollLink
            to="kontakt"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            {isSubPage ? '' : 'KONTAKT'}
          </ScrollLink>
        </div>
      )}

      {windowWidth < 550 && isMenuOpen && (
        <div className="mobileMenu">
          <ScrollLink to="top" className="navLink" onClick={handleHomeClick}>
            HOME
          </ScrollLink>
          <ScrollLink
            to="WilkWoodAbout"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            O NAS
          </ScrollLink>
          <ScrollLink
            to="WilkWoodOferta"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            OFERTA
          </ScrollLink>
          <ScrollLink
            to="WilkWoodRealizacje"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            REALIZACJE
          </ScrollLink>
          <ScrollLink
            to="kontakt"
            smooth={true}
            duration={800}
            offset={-90}
            className="navLink"
            onClick={handleNavLinkClick}
          >
            KONTAKT
          </ScrollLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
