import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

import obszarFirst from '../images/blog/obszar-1.jpg';
import obszarSecond from '../images/blog/obszar-2.jpg';
import obszarThird from '../images/blog/obszar-3.jpg';

const Obszar = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='meble'>
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/obszar" />
        <title>Obszar działania mebli na wymiar - WilkWood</title>
      </Helmet>
      <div className='section-header obszar'>
        <h1>Nasz obszar działania</h1>
      </div>
      <div className='meble blog obszar-cont'>
        <div className="wpis-container">
          <img className="obszar-img" src={obszarFirst} alt="meble na wymiar małopolska" />
          <div className="wpis-text">
            <h1>Niezrównane meble na wymiar</h1>
            <p>Witamy w <strong>Wilkwood</strong> - Jesteśmy ekspertami w projektowaniu i produkcji mebli na wymiar, które dostarczają nie tylko estetycznych wrażeń, ale także doskonałej funkcjonalności. Nasza firma działa na terenie Małopolski, a także w miastach Kraków i Rzeszów, oferując naszym Klientom wyjątkowe i spersonalizowane rozwiązania do każdego wnętrza.</p>
            <p><strong>O Nas</strong> : Od wielu lat inspirujemy i spełniamy marzenia naszych Klientów o pięknych i praktycznych meblach na wymiar. Nasze zespoły projektantów i stolarzy współpracują ściśle z naszymi Klientami, aby dostarczyć produkty, które idealnie odpowiadają ich potrzebom i stylowi życia. Jako liderzy branży meblarskiej w Małopolsce, Tarnowie, Krakowie i Rzeszowie oraz okolicach cieszymy się zaufaniem wielu Klientów, którzy doceniają naszą jakość, zaangażowanie i profesjonalizm.</p>
          </div>
        </div>
        <div>
          <p><strong>Nasza Oferta:</strong> Oferujemy szeroki zakres <strong>mebli na wymiar małopolska</strong>, które są dostosowane do indywidualnych wymagań naszych Klientów. Nasze usługi obejmują, między innymi:</p>
          <div className="grid-container-obszar">
            <div className="grid-item-obszar">
              <strong>Kuchnie Na Wymiar</strong> : Nasze kuchnie na wymiar to połączenie doskonałej ergonomii z wysoką estetyką. Zapewniamy funkcjonalne rozwiązania, które maksymalnie wykorzystują dostępną przestrzeń, a jednocześnie tworzą miejsce, gdzie gotowanie staje się prawdziwą przyjemnością.
            </div>
            <div className="grid-item-obszar">
              <strong>Szafy i Garderoby Na Wymiar</strong> : Dzięki naszym szafom na wymiar zaoszczędzisz miejsce i zachowasz porządek w swojej garderobie. Zapewniamy wygodne rozwiązania do przechowywania ubrań, butów i akcesoriów, które będą doskonale współgrać z wystrojem Twojego pomieszczenia.
            </div>
            <div className="grid-item-obszar">
              <strong>Meblościanki i Regały Na Wymiar</strong> : Oferujemy mebloscianki i regały, które będą nie tylko praktyczne, ale także pięknie zdobią Twoje wnętrza. Dostosujemy je do Twoich potrzeb, zapewniając idealne rozwiązania do przechowywania, prezentacji i wyeksponowania Twoich ulubionych przedmiotów.
            </div>
            <div className="grid-item-obszar">
              <strong>Mebelki Dziecięce Na Wymiar</strong> : Tworzymy bajkowe przestrzenie dla najmłodszych. Nasze meble dziecięce na wymiar łączą funkcjonalność z wyjątkowym designem, zachęcając dzieci do zabawy i kreatywności.
            </div>
            <div className="grid-item-obszar">
              <strong>Meble Do Salonu i Sypialni</strong> : Oferujemy różnorodne meble do salonu i sypialni, w tym sofy, łóżka, szafki, komody i wiele innych, które dostosujemy do Twoich preferencji i potrzeb.
            </div>
          </div>
        </div>
        <div>
          <div className='obszar-containter'>
            <img className="obszar-img" src={obszarSecond} alt="meble na wymiar Rzeszów" />
          </div>
          <p><strong>Nasz Proces</strong>:</p>
          <div className="grid-container-proces">
            <div className="grid-item-obszar">
              <strong>Konsultacja</strong> : Rozpoczynamy od bezpłatnej konsultacji, podczas której omawiamy Twoje wymagania, preferencje stylistyczne i budżet. Nasz zespół służy pomocą i inspiracją na każdym etapie.
            </div>
            <div className="grid-item-obszar">
              <strong>Projektowanie</strong> : Nasi doświadczeni projektanci stworzą trójwymiarowe wizualizacje, które pozwolą Ci zobaczyć, jak Twoje <strong>meble na wymiar Kraków</strong> będą prezentować się w rzeczywistości.
            </div>
            <div className="grid-item-obszar">
              <strong>Produkcja</strong> : Po zaakceptowaniu projektu przystępujemy do produkcji mebli z wykorzystaniem najwyższej jakości materiałów, co gwarantuje trwałość i estetykę naszych produktów.
            </div>
            <div className="grid-item-obszar">
              <strong>Dostawa i Montaż</strong> : Nasz profesjonalny zespół dostarczy meble na wymiar pod wskazany adres i przeprowadzi ich montaż, zapewniając pełne zadowolenie z naszych usług.
            </div>
          </div>
        </div>
        <div>
        <div className='obszar-containter'>
            <img className="obszar-img" src={obszarThird} alt="meble na wymiar Kraków" />
          </div>
          <p><strong>Dlaczego My?</strong></p>
          <div className="grid-container-proces">
            <div className="grid-item-obszar">
              <strong>Doświadczenie</strong> : Jesteśmy w branży od wielu lat, co pozwoliło nam zdobyć bogate doświadczenie i wyspecjalizować się w projektowaniu i produkcji mebli na wymiar.
            </div>
            <div className="grid-item-obszar">
              <strong>Kreatywność</strong> : Nasz zespół projektantów stale poszukuje nowych inspiracji i rozwiązań, aby dostarczyć Klientom unikalne i nietuzinkowe meble.
            </div>
            <div className="grid-item-obszar">
              <strong>Jakość</strong> : Stawiamy na najwyższą jakość materiałów i precyzyjne wykonanie, aby zapewnić naszym Klientom trwałe i funkcjonalne meble na lata.
            </div>
            <div className="grid-item-obszar">
              <strong>Indywidualne Podejście</strong> : Nasze <strong>meble na wymiar Rzeszów</strong> są tworzone z myślą o Twoich potrzebach, preferencjach i stylu życia, co gwarantuje pełne zadowolenie z naszych usług.
            </div>
          </div>
        </div>
        <p>Zapraszamy do <strong>Wilkwood</strong>, aby zrealizować swoje marzenia o wyjątkowych meblach, które idealnie dopasują się do Twojego wnętrza. Skontaktuj się z nami, a nasi specjaliści z przyjemnością odpowiedzą na Twoje pytania i umówią się na bezpłatną konsultację. Razem stworzymy meble na wymiar, które będą odzwierciedleniem Twojego stylu i gustu.</p>
      </div>
    </section>
  );
};

export default Obszar;