import React, { useState, useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-responsive-modal';
import Spinner from '../images/spinner.gif';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-modal/styles.css';
import { Helmet } from 'react-helmet';

import LazienkaFront from '../images/lazienka/lazienka-1.png';
import LJeden from '../images/lazienka/lazienka-1.jpg';
import LaJeden from '../images/lazienka/lazienka-1.1.jpg';
import LazJeden from '../images/lazienka/lazienka-1.2.jpg';
import LaziJeden from '../images/lazienka/lazienka-1.3.jpg';

import LDwa from '../images/lazienka/lazienka-2.jpg';
import LaDwa from '../images/lazienka/lazienka-2.1.jpg';
import LazDwa from '../images/lazienka/lazienka-2.2.jpg';

import LTrzy from '../images/lazienka/lazienka-3.jpg';
import LaTrzy from '../images/lazienka/lazienka-3.1.jpg';
import LazTrzy from '../images/lazienka/lazienka-3.2.jpg';

import LCztery from '../images/lazienka/lazienka-4.jpg';
import LaCztery from '../images/lazienka/lazienka-4.1.jpg';
import LazCztery from '../images/lazienka/lazienka-4.2.jpg';

import LPiecFront from '../images/lazienka/lazienka-5-front.png';
import LPiec from '../images/lazienka/lazienka-5.jpg';
import LaPiec from '../images/lazienka/lazienka-5.1.jpg';
import LazPiec from '../images/lazienka/lazienka-5.2.jpg';

import LSzescFront from '../images/lazienka/lazienka-6-front.png';
import LSzesc from '../images/lazienka/lazienka-6.jpg';
import LaSzesc from '../images/lazienka/lazienka-6.1.jpg';
import LazSzesc from '../images/lazienka/lazienka-6.2.jpg';
import LaziSzesc from '../images/lazienka/lazienka-6.3.jpg';

const MebleLazienkowe = () => {
  const [openModal, setOpenModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [cardLoading, setCardLoading] = useState(true);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (images) => {
    setGalleryImages(images);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cards = [
    {
      id: 2,
      image: LDwa,
      gallery: [
        { original: LDwa },
        { original: LaDwa },
        { original: LazDwa },
      ],
      description: 'Wisząca szafka z podwójnym zlewem z szarej płyty i drewnianym blatem',
    },
    {
      id: 5,
      image: LPiecFront,
      gallery: [
        { original: LPiec },
        { original: LaPiec },
        { original: LazPiec },
      ],
      description: 'Wisząca szafka z lakierowanymi białymi frontami i podwójnym zlewem',
    },
    {
      id: 1,
      image: LazienkaFront,
      gallery: [
        { original: LJeden },
        { original: LaJeden },
        { original: LazJeden },
        { original: LaziJeden },
      ],
      description: 'Szafka łazienkowa w kolorze szarego popiołu z drewnianym blatem',
    },
    {
      id: 1,
      image: LSzescFront,
      gallery: [
        { original: LSzesc },
        { original: LaSzesc },
        { original: LazSzesc },
        { original: LaziSzesc },
      ],
      description: 'Lakierowane szafki z wpuszczanym zlewem i dozownikiem mydła',
    },
    {
      id: 4,
      image: LCztery,
      gallery: [
        { original: LCztery },
        { original: LazCztery },
        { original: LaCztery },
      ],
      description: 'Biała łazienka z lustrem ledowym i złotymi okuciami',
    },
    {
      id: 3,
      image: LaTrzy,
      gallery: [
        { original: LTrzy },
        { original: LaTrzy },
        { original: LazTrzy },
      ],
      description: 'Ciemnoszara szafka z czarnym blatem i czarnymi uchwytami',
    },
  ];

  const galleryItems = galleryImages.map((image) => ({
    original: image.original,
    thumbnail: image.original,
  }));

  return (
    <section className='meble' ref={scrollContainerRef}>
      <Helmet>
        <link rel="canonical" href="https://wilkwood.pl/meble-lazienkowe" />
        <title>Meble łazienkowe na wymiar Tarnów</title>
        <meta name="description" content="Zamów meble łazienkowe na wymiar Tarnów. Oferujemy spersonalizowane rozwiązania meblarskie dostosowane do Twojego gustu i potrzeb. Skontaktuj się z nami już dziś i rozpocznij projektowanie swojej wymarzonej łazienki!" />
      </Helmet>
      <div className="card-grid">
        {cards.map((card) => (
          <div
            className="card"
            key={card.id}
            onClick={() => handleCardClick(card.gallery)}
          >
              {cardLoading && (
                <img src={Spinner} className='mini-spinner' alt="Loading Spinner" />
              )}
              <img
                src={card.image}
                alt={card.description}
                onLoad={() => setCardLoading(false)}
                style={{ display: cardLoading ? 'none' : 'block' }}
              />
            <div className="card-overlay">
              <span className="card-text">Zobacz galerię</span>
            </div>
            <div className="card-description abth">{card.description}</div>
          </div>
        ))}
      </div>
      <Modal open={openModal} onClose={closeModal} center>
        <div className="modal-content">
          <div className="image-gallery-container">
          {galleryImages.length > 0 ? (
              <ImageGallery items={galleryItems} additionalClass="equal-width" />
            ) : (
              <img src={Spinner} alt="Loading Spinner" />
            )}
          </div>
        </div>
      </Modal>
      <div className='subpage-description'>
        <h1 className='realizacje-header'><strong>Meble na zamówienie Tarnów</strong> - Twórz swoją unikalną łazienkę z naszymi meblami</h1>
        <p>Jeżeli marzysz o oryginalnej i funkcjonalnej łazience, która odzwierciedli Twój osobisty styl, zapraszamy do skorzystania z naszej oferty mebli na zamówienie w Tarnowie. Oferujemy wyjątkowe rozwiązania, które pozwolą Ci stworzyć przestrzeń łazienkową idealnie dopasowaną do Twoich potrzeb i preferencji.</p>
        <p>Nasze meble na zamówienie to gwarancja jakości, trwałości i estetyki. Wykonujemy je z najwyższą precyzją, używając wysokiej jakości materiałów. Współpracujemy z doświadczonymi stolarzami, którzy z pasją tworzą meble, dbając o najdrobniejsze detale. Dzięki temu możemy zapewnić Ci meble, które nie tylko będą piękne, ale także funkcjonalne i wytrzymałe.</p>
        <p>Wybierając meble na zamówienie w Tarnowie, otrzymasz nie tylko funkcjonalne i estetyczne rozwiązania, ale także pełną obsługę, począwszy od konsultacji i pomiarów, aż po dostawę i montaż mebli. Nasza ekipa jest terminowa i dokładna, dbając o to, aby proces realizacji zamówienia przebiegał sprawnie i zgodnie z ustalonym planem.</p>
        <p>Zainwestuj w swoją łazienkę i stwórz przestrzeń, w której będziesz cieszyć się codziennymi chwilami relaksu. Skontaktuj się z nami już dziś, aby stworzyć swoje <strong>meble na zamówienie Tarnów</strong>. Jesteśmy gotowi spełnić Twoje marzenia i dostarczyć Ci meble, które będą doskonale pasować do Twojego stylu życia i wnętrza.</p>
      </div>
    </section>
  );
};

export default MebleLazienkowe;